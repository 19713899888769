/**
* Setup webpack public path
* to enable lazy-including of
* js chunks
*
*/
/* ----global urls:true---- */
import 'bootstrap';
import 'waypoints/lib/jquery.waypoints';
import 'waypoints/lib/shortcuts/inview';
import 'waypoints/lib/shortcuts/infinite';

import initGoogleMap from './scripts/google-maps';

const $ = window.jQuery;


$(() => {
	$('#toggleNav').on('click', (e) => {
		const {currentTarget} = e;

		$('#mainNav').toggleClass('show');
		$(currentTarget).toggleClass('open');
	});

	$('img').each((index, item) => {

		$(item).addClass('ready');

		new window.Waypoint.Inview({
			element: $(item),
			enter: function(direction) {
				$(item).addClass('inview');
			},
			entered: function(direction) {
				//console.log('entered');
			},
			exit: function(direction) {

			},
			exited: function(direction) {
				//$(this[0,'element']).removeClass('inview');
			}
		});
	});

	// const showHideLoadingImage = (index, item) => {
	// 	setTimeout(() => {
	// 		$('#loading .loading__img').removeClass('show');
	// 		$(item).addClass('show');
	//
	// 	}, 1000 * index);
	// }

	const loading = $('#loading');

	if (loading.length > 0) {
		$('.site__body.home').addClass('noscroll');
		setTimeout(() => {
			$('.site__body.home').removeClass('noscroll');
			loading.fadeOut();
		}, 3000);
		// const loadingImages = $('#loading .loading__img');
		// const length = loadingImages.length;

		// loadingImages.each((index, item) => {
		// 	showHideLoadingImage(index, item);
		// });
		//
		// setTimeout(() => {
		// 	$('.site__body.home').addClass('loaded');
		// 	$('#loading').addClass('hide');
		// }, 1000 * (length +1));
	}

	// eslint-disable-next-line no-unused-vars
	const infinite = new window.Waypoint.Infinite({
		element: $('#projects')[0],
		more: '.more-posts',
		items: '.projects__item',

	});
});

$(window).resize(() => {

});

// Make Google maps globally available
if (!('initMap' in window)) {
	window.initMap = initGoogleMap;
}


/**
 * Detects if mobile browser
 *
 */
export function isMobile() {
	const { userAgent } = navigator;

	if (userAgent.indexOf('iPhone') !== -1
		|| userAgent.indexOf('Android') !== -1
		|| userAgent.indexOf('webOS') !== -1) {
		return true;
	}
	return false;
}

export function isTablet() {
	const { userAgent } = navigator;
	if (userAgent.indexOf('Mobi') !== -1) {
		return true;
	}
	return false;
}

export function isIphone() {
	const { userAgent } = navigator;

	if (userAgent.indexOf('iPhone') !== -1) {
		return true;
	}
	return false;
}

export function isIpad() {
	const { userAgent } = navigator;

	if (userAgent.indexOf('iPad') !== -1) {
		return true;
	}
	return false;
}

import {isMobile} from '../utils/detect-browser';

const $ = window.jQuery;

export default function () {
	const {google} = window;
	const latPt = -38.269729;
	const longPt = 144.548450;
	const markerPath = `${$('#map').data('path')}/assets/svg/map-marker.svg`;
	const mapContainer = document.getElementById('map');

	const mapStyle = [
		{
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#b1b5c2"
				}
			]
		},
		{
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#616161"
				}
			]
		},
		{
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"color": "#f5f5f5"
				}
			]
		},
		{
			"featureType": "administrative.land_parcel",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#bdbdbd"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#b1b5c2"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#757575"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#b1b5c2"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#9e9e9e"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#757575"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dadada"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#616161"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#9e9e9e"
				}
			]
		},
		{
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e5e5e5"
				}
			]
		},
		{
			"featureType": "transit.station",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#eeeeee"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#a0a4b1"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#9e9e9e"
				}
			]
		}
	]

	if (mapContainer) {
		/* eslint-disable no-unused-vars */
		const map = new google.maps.Map(mapContainer, {
			center: {lat: latPt, lng: longPt},
			zoom: 11,
			styles: mapStyle,
			disableDefaultUI: true
		});

		const markerImage = {
			url: markerPath,
			size: new google.maps.Size(20, 27),
			scaledSize: new google.maps.Size(20, 27),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(10, 27),
			optimzed: false
		};

		new google.maps.Marker({
			position: {lat: -38.278610, lng: 144.491090},
			map,
			icon: markerImage
		});

		new google.maps.Marker({
			position: {lat: -38.286500, lng: 144.614240},
			map,
			icon: markerImage
		});

		map.setOptions({scrollwheel: false});

		if (isMobile()) {
			map.setOptions({draggable: false});
		}
	}
}
